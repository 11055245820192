<template>
    <div class="flex flex-col p-3 pt-6 sm:p-6">
        <Khead/>
        <div class="flex justify-center">
            <div class=" bg-white sm:w-frame  border mt-8 rounded"
            :class="tokenVerify ? 'mb-48' : 'mb-64'">
                <div class=" container mx-auto p-8 sm:px-12">
                    <KsMailEnterForPasswordReset class="mb-10" v-show="enterMail" :enterMail="enterMail" @listenEnterMail ="enterMail = $event" />
                    <KsResetPwdTokenVerify class="mb-10" v-show="!enterMail && !tokenVerify" :tokenVerify="tokenVerify" @listenTokenVerify ="tokenVerify = $event" />
                    <KsPwdReset v-show="tokenVerify"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KsPwdReset from '../../components/AuthComponents/KsPwdReset';
import KsMailEnterForPasswordReset from '../../components/AuthComponents/KsMailEnterForPasswordReset';
import KsResetPwdTokenVerify from '../../components/AuthComponents/KsResetPwdTokenVerify';
import Khead from '@/components/partials/Khead'
export default {
    components: {
        KsMailEnterForPasswordReset,
        KsResetPwdTokenVerify,
        KsPwdReset,
        Khead
    },
    data(){
        return {
            enterMail: true,
            tokenVerify: false,
        }
    },
    methods: {
        
    },
}
</script>

<style scoped>

</style>