<template>
    <div >
        <div class="mb-6 flex flex-col items-center justify-center">
            <span class="mt-4 text-xl sm:text-3xl text-center font-semibold">{{ $t("sign.pwdRecoverTitle") }}</span>
        </div>

        <label class="mt-3 text-sm">{{ $t("sign.resetLabel") }}</label>
        <div class="mt-1 bg-gray-300 inline-flex w-full" style="border-radius: 1rem;">
            <button @click="resetBy" type="button"
                :class="!telephone?'bg-white transition-all duration-500 transform ':''"
                class="focus:outline-none m-2 text-gray-800 font-bold py-2 px-4 w-1/2 flex justify-center"
                style="border-radius: 0.75rem;">
                <Iconsmail />
                <span class="ml-1 text-xs">Email</span>
            </button>
            <button @click="resetBy"  type="button"
                :class="telephone?'bg-white transition-all duration-500 transform ':''"
                class="focus:outline-none m-2 text-gray-800 font-bold py-2 px-4 w-1/2 flex justify-center"
                style="border-radius: 0.75rem;">
                <Iconphone />
                <span class="ml-1 text-xs">{{ $t("rechargeSuccess.phone") }}</span>
            </button>
        </div>

        <form v-show="!telephone" @submit.prevent="submitByMail" data-vv-scope="form-1">
            <div class="mt-4">
                <label class="text-base" for="email">{{ $t("sign.resetMailLabel") }}</label>
                <br>
                <div
                    class="bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row items-center"
                    :class="{ ' border-tex-2': submittedMail && errors.has('form-1.email') }">
                    
                    <span class="p-3">
                        <Iconmail />
                    </span>
                    <input  class="leading-tight w-full focus:outline-none focus:bg-white" id="email" name="email"
                        type="text"
                        v-validate="'required|email'"
                        v-model="mail"/>
                </div>
                <div v-show="submittedMail && errors.has('form-1.email')" class="flex items-center mt-2">
                    <IconError />
                    <span  class="ml-1 text-tex-2">{{ $t("sign.errorMail") }}</span>
                </div>
            </div>

            <div v-show="userNotFound" class="mt-3 flex items-center">
                <span><IconError/></span>
                <i class="ml-1 text-tex-2">{{ $t("sign.messageError") }}</i>
            </div>

            <button type="submit" class="mt-6 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded focus:outline-none">{{ $t("sign.confirmBtn") }}</button>
        
        </form>

         <form v-show="telephone" @submit.prevent="submitByPhone" data-vv-scope="form-2">
            <div class="mt-4">
                <label class="text-base" for="phone">{{ $t("sign.resetPhoneLabel") }}</label>
                <br>
                <div >
                    <vue-tel-input 
                        inputClasses=""
                        placeholder=''
                        name="phone" 
                        inputId="phone"
                        :enabledCountryCode="true"
                        :validCharactersOnly="true"
                        @country-changed="countryChanged"
                        wrapperClasses = "h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded"
                        v-model="phone">
                    </vue-tel-input>
                    <div v-show="phoneError" class="flex items-center mt-1">
                        <IconError/><span class="ml-1 text-tex-2">{{ $t("sign.errorNumber") }}</span>
                    </div>
                </div>
            </div>

            <div v-show="userNotFound" class="mt-3 flex items-center">
                <span><IconError/></span>
                <i class="ml-1 text-tex-2">{{ $t("sign.messageError") }}</i>
            </div>

            <button type="submit" class="mt-6 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded focus:outline-none">{{ $t("sign.confirmBtn") }}</button>
        
        </form>

    </div>
</template>

<script>
import axios from 'axios'
import Iconmail from "@/assets/svg/icons/mail.svg?inline";
import Iconsmail from "@/assets/svg/icons/smallmail.svg?inline";
import Iconphone from "@/assets/svg/icons/smallphone.svg?inline";
import IconError from "@/assets/svg/icons/errorIcon.svg?inline";
import {VueTelInput} from 'vue-tel-input'
export default {
    props:{
        enterMail:{
            type: Boolean,
        }
    },
    components: {
        Iconmail,
        Iconsmail,
        VueTelInput,
        IconError,
        Iconphone
    },
    data() {
        return {
            mail: '',
            phone: '',
            submittedMail: false,
            submittedPhone: false,
            enterMailData: this.enterMail,
            userNotFound: false,
            telephone: false,
            phoneError: false,
            countryCode: null,
        }
    },
    watch: {
        phone(){
            this.phoneError = false
        },
        mail(){
            this.submittedMail = false
        }
    },
    methods: {
        countryChanged(country) {
            this.countryCode = country.dialCode
        },
        resetBy(){
            this.telephone = !this.telephone
        },
        passEnterMail(){
            this.enterMailData = false;
            this.$emit('listenEnterMail',this.enterMailData);
        },
        submitByMail(){
            this.userNotFound = false
            this.submittedMail = true;
            this.$validator.validateAll('form-1').then(valid => {
            if (valid) {
                    axios.post('/users/pwdReset', {
                        resetByPhone: false,
                        username: this.mail
                    })
                    .then(res => {
                        console.log(res)
                        this.$store.commit('mutPwdResetMail', this.mail)
                        this.passEnterMail()
                    })
                    .catch(err => {
                        console.log(err.response.data)
                        if (err.response.data.status == 403){
                            this.userNotFound = true
                        }
                    })
                }
            })
        },
        submitByPhone(){
            this.phone = this.phone.replace(/\s+/g, '');
            if(this.phone.length >= 8) {
                this.userNotFound = false
                this.submittedPhone = true;
                this.$validator.validateAll('form-2').then(valid => {
                if (valid) {
                        axios.post('/users/pwdReset', {
                            resetByPhone: true,
                            username: this.countryCode + this.phone
                        })
                        .then(res => {
                            console.log(res)
                            this.$store.commit('mutPwdResetMail', this.countryCode + this.phone)
                            this.passEnterMail()
                        })
                        .catch(err => {
                            console.log(err.response.data)
                            if (err.response.data.status == 403){
                                this.userNotFound = true
                            }
                        })
                    }
                })
            }
            else {
                this.phoneError = true
            }
        }
    },
}
</script>

<style scoped>
.vue-tel-input {
    border-radius: 3px;
    display: flex;
    border: 1px solid #C6D6EC;
    text-align: left;
}

.vue-tel-input:hover{
    border-color: #A5BCE0;
}
.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: #4661B9;
}

.vue-tel-input:focus{
    outline: none !important;
    border-color: violet;
}

::v-deep .vti__dropdown,
::v-deep .vti__dropdown.open:focus,
::v-deep .vti__dropdown.open:focus-within  {
    outline-style: none;
    border-top-left-radius: 0.125rem/* 2px */;
    border-bottom-left-radius: 0.125rem/* 2px */;
}
</style>