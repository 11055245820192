<template>
    <form @submit.prevent="submit" class="">
        <div class=" flex items-center justify-center ">
            <Iconlock/>
            <span class="text-2xl font-semibold">{{ $t("codeVerify.title") }}</span>
        </div>

        <div class="mt-6 mb-16 px-6 md:px-10">
            <p class="text-center">{{ $t("codeVerify.pwdResetSubtitle",{channel: channelSend, channelParam: mail}) }}</p>
        </div>

        <div class="mt-4">
            <label class="text-base" for="code">Code</label>
            <br>
            <input 
                class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                v-validate="'required|numeric|max:5|min:5'"
                id="code" 
                name="code" 
                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type="number"
                min="0"
                maxlength="5"
                v-model="code"
                :class="{ ' border-tex-2': submitted && errors.has('code') }">
                <br><i v-show="codeError" class="text-tex-2">{{ $t("codeVerify.codeInvalid") }}</i>
        </div>
    
        <button class="focus:outline-none mt-5 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded" >
            <div v-show="!request" >{{ $t("codeVerify.btn") }}</div>
            <LoaderCss class="ml-16" v-show="request" />
        </button>
        
    </form>
</template>

<script>
import LoaderCss from '@/components/LoaderCss'
import Iconlock from '@/assets/svg/dashbIcons/lock.svg?inline';
import axios from 'axios';
export default {
    props:{
        tokenVerify:{
            type: Boolean,
        }
    },
    data(){
        return{
            tokenVerifyData: this.tokenVerify,
            request: false,
            submitted: false,
            codeError: false,
            code: ''
        }
    },
    computed: {
        mail(){
            return this.$store.getters.getPwdResetMail
        },
        channelSend(){
            const emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if (emailRegex.test(this.mail)) {
                return 'mail'
            }
            else {
                return 'sms'
            }
        }
    },
    
    components: {
        Iconlock,
        LoaderCss
    },
    
    methods: {
        passTokenVerify(){
            this.tokenVerifyData = true;
            this.$emit('listenTokenVerify',this.tokenVerifyData);
        },
        submit(){
            this.codeError = false
            this.submitted = true;
            this.$validator.validate().then(valid => {
            if (valid) {
                this.request = true
                axios.post(`/users/pwdResetChecktoken/${this.code}`,{
                    username: this.mail
                })
                .then(res => {
                    console.log(res)
                    this.$store.commit('mutPwdResetToken', this.code)
                    this.passTokenVerify()
                })
                .catch(err => {
                    this.request = false
                    console.log(err.response.data)
                    const message = err.response.data.message;
                    if (message == 'Bad Request') this.codeError = true
                })
            
            }
            });
        },
    },
}
</script>

<style scoped>

</style>