<template>
    <form @submit.prevent="submitToConnect" class=" mb-8 sm:mb-32">
        <div class=" flex flex-col items-center justify-center">
            <span class="mt-4 text-xl sm:text-3xl text-center font-semibold">{{ $t("sign.pwdRecoverTitle") }}</span>
        </div>

        <div class="mt-6">
            <label class="text-base " for="pwd">{{ $t("sign.pwdNew") }}</label>
            <br>
            <div 
                class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                :class="{ ' border-tex-2': submitted && errors.has('pwd') }">
                <input 
                    class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white" 
                    v-model="password"
                    id="pwd" 
                    name="pwd" 
                    :type="pwdShow ? 'text' : 'password'"
                    v-validate="'required|min:8|number|charac|upCase'"
                    ref="pwd">
                <Iconeye 
                    class="m-2" 
                    @click="pwdShow = !pwdShow"
                    v-if="!pwdShow"/>
                <Iconeyeoff 
                    class="m-2" 
                    @click="pwdShow = !pwdShow"
                    v-if="pwdShow"/>
            </div>
        </div>

        <div class="grid grid-rows-2 grid-flow-col gap-2 mt-3" v-if="password.length !=0 && !isValidPwd">
            <div class="flex flex-row items-center"><Icononline v-if="validMsg.carac"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.caractere") }}</span></div>
            <div class="flex flex-row items-center"><Icononline v-if="validMsg.num"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.chiffre") }}</span></div>
            <div class="flex flex-row items-center"><Icononline v-if="validMsg.upper"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.lettre") }}</span></div>
            <div class="flex flex-row items-center"><Icononline v-if="validMsg.eightCarac"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.eightcar") }}</span></div>
        </div>

        <div class="mt-6">
            <label class="text-base " for="pwdconfirm">{{ $t("sign.pwdConfirm") }}</label>
            <br>
            <div 
                class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                :class="{ ' border-tex-2': submitted && errors.has('pwdconfirm') }">
                <input 
                    class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white" 
                    id="pwdconfirm" 
                    name="pwdconfirm" 
                    :type="pwdConfirmShow ? 'text' : 'password'"
                    v-validate="'required|confirmed:pwd'"
                    data-vv-as="pwd">
                <Iconeye 
                    class="m-2" 
                    @click="pwdConfirmShow = !pwdConfirmShow"
                    v-if="!pwdConfirmShow"/>
                <Iconeyeoff 
                    class="m-2" 
                    @click="pwdConfirmShow = !pwdConfirmShow"
                    v-if="pwdConfirmShow"/>
            </div>
        </div>

        

        <button class="mt-6 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded focus:outline-none">{{ $t("counterRecharge.btn") }}</button>
        
    </form>
</template>

<script>
import Iconeye from '../../assets/svg/icons/eye.svg?inline';
import Iconeyeoff from '../../assets/svg/icons/eyeoff.svg?inline';
import Icononline from '../../assets/svg/icons/online.svg?inline';
import Iconoffline from '../../assets/svg/icons/offline.svg?inline';
import axios from 'axios'
export default {
    components: {
        Iconeye,
        Iconeyeoff,
        Icononline,
        Iconoffline
    },
    data(){
        return {
            password: '',
            pwdShow : false,
            pwdConfirmShow: false,
            submitted: false,
            validMsg: {
                carac: false,
                num: false,
                upper: false,
                eightCarac: false
            },
        }
    },

    computed: {
        isValidPwd(){
           if (this.validMsg.carac && this.validMsg.num && this.validMsg.upper && this.validMsg.eightCarac) return true
           return false
        },
        code(){
            return this.$store.getters.getPwdResetToken
        },
        mail(){
            return this.$store.getters.getPwdResetMail 
        }
    },

    watch: {
        password(value){
            this.password = value;
            this.validatePassword(value);
        }
    },

    methods: {
        switchPwdType(){
            this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
        },

        validatePassword(value){
            let eigh = /.{8,}/;
            let numreq = /[0-9]+/;
            let upp = /[A-Z]+/;
            let car = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
            eigh.test(value) ? this.validMsg.eightCarac = true : this.validMsg.eightCarac = false;

            numreq.test(value) ? this.validMsg.num = true : this.validMsg.num = false;
            
            upp.test(value) ? this.validMsg.upper = true : this.validMsg.upper = false;
            
            car.test(value) ? this.validMsg.carac = true : this.validMsg.carac = false;
           
        },

        submitToConnect(){
            this.submitted = true;
            this.$validator.validate().then(valid => {
            if (valid) {
                axios.post(`/users/pwdReset/${this.code}`, {
                    username: this.mail,
                    password: this.password
                })
                .then(res => {
                    console.log(res)
                    this.$router.push("/signsuccess");
                })
                .catch(err => {
                    console.log(err)
                    
                })
                
            }
            });
        }
    },
}
</script>

<style scoped>

</style>